var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:_vm.$style['container'],attrs:{"fluid":""}},[_c('v-overlay',{attrs:{"value":_vm.processing}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('ToolBar',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('v-subheader',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.paginationText))])]},proxy:true},{key:"right",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.disabledAddButton)?_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.moveToCreate}},on),[_c('v-icon',[_vm._v("mdi-plus-box")])],1):_vm._e()]}}])},[_c('span',[_vm._v("新規登録")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.disabledFilterButton)?_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onFilter($event)}}},on),[_c('v-badge',{attrs:{"value":_vm.filtered,"dot":"","top":"","color":"red","offset-x":"8","offset-y":"8"}},[_c('v-icon',[_vm._v("mdi-filter-outline")])],1)],1):_vm._e()]}}])},[_c('span',[_vm._v("フィルタ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.onRefresh}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("再読込")])])]},proxy:true}])}),_c('div',{class:_vm.$style['container-content']},[_c('v-data-table',{attrs:{"headers":_vm.columnsForTable,"items":_vm.entities,"items-per-page":_vm.perPage,"loading":_vm.loading,"loading-text":"読込中...","no-data-text":"データがありません","disable-sort":"","fixed-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"flex-nowrap mx-n4",attrs:{"align-content":"center"}},[_vm._t("actions.before",null,{"item":item}),_vm._t("actions.detail",[_c('DetailAction',{attrs:{"action":function () { return _vm.moveToShow(item); }}})],{"item":item}),_vm._t("actions.remove",[_c('RemoveAction',{attrs:{"action":function () { return _vm.remove(item); }}})],{"item":item}),_vm._t("actions.after",null,{"item":item})],2)]}}],null,true)})],1),(!_vm.disabledPagination)?_c('v-pagination',{class:_vm.$style['pagination'],attrs:{"length":_vm.pageLength,"value":_vm.currentPage,"total-visible":7},on:{"input":_vm.onChangePage}}):_vm._e(),_c('FilterModal',{attrs:{"resourceName":_vm.resourceName,"columns":_vm.columns}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }