<template>
  <v-col>
    <v-icon :disabled="disabled" @click="action">
      mdi-file-document-outline
    </v-icon>
  </v-col>
</template>

<script>
export default {
  props: {
    action: {
      type: Function,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>
