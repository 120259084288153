<template>
  <v-dialog v-model="open" :content-class="$style['dialog']">
    <v-card>
      <v-card-title :class="$style['dialog-card-title']">
        フィルタ条件設定
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-0">
        <v-container fluid class="pa-5">
          <v-row class="ma-0" align="baseline" justify="start">
            <Label
              :class="$style['label']"
              :size="14"
              :label="'対象フィールド'"
            />
            <div class="flex-grow-1">
              <SelectField
                v-model="input.field"
                name="field"
                text-key="text"
                value-key="value"
                :options="fieldOptions"
                :hide-details="true"
              />
            </div>
          </v-row>
          <v-row class="ma-0" align="baseline" justify="start">
            <Label :class="$style['label']" :size="14" :label="'条件'" />
            <div class="flex-grow-1">
              <SelectField
                v-model="input.cond"
                name="cond"
                text-key="text"
                value-key="cond"
                :options="conditions"
                :hide-details="true"
              />
            </div>
          </v-row>
          <v-row class="ma-0" align="baseline" justify="start">
            <Label :class="$style['label']" :size="14" :label="'値'" />
            <div class="flex-grow-1">
              <template v-if="useValue">
                <TextField
                  v-if="fieldType === 'string'"
                  v-model="input.value"
                  name="value"
                  :hide-details="true"
                />
                <DateField
                  v-if="fieldType === 'date'"
                  v-model="input.value"
                  name="value"
                  :hide-details="true"
                />
              </template>
            </div>
          </v-row>
          <v-btn
            class="mx-4 my-2"
            color="primary"
            @click="add"
            :disabled="disabledAddButton"
          >
            <v-icon left>mdi-plus</v-icon>追加
          </v-btn>
        </v-container>
        <v-divider />
        <v-data-table
          :headers="filterColumns"
          :items="filters"
          height="250px"
          no-data-text="フィルタは未設定です"
          disable-sort
          fixed-header
          hide-default-footer
        >
          <template v-slot:item.field="{ value }">
            <span>{{ renderField(value) }}</span>
          </template>
          <template v-slot:item.cond="{ value }">
            <span>{{ renderCondition(value) }}</span>
          </template>
          <template v-slot:item.remove="{ item }">
            <v-icon @click="remove(item.index)">
              mdi-close
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider />
      <v-card-actions class="pa-3">
        <v-row class="ma-0" align="baseline" justify="end">
          <v-btn class="mx-1" @click="apply"
            ><v-icon left>mdi-filter-outline</v-icon>適用</v-btn
          >
          <v-btn class="mx-1" @click="clearAll"
            ><v-icon left>mdi-close</v-icon>クリア</v-btn
          >
          <v-btn class="mx-1" @click="cancel"
            ><v-icon left>mdi-cancel</v-icon>キャンセル</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SelectField from "./field/select";
import TextField from "./field/text";
import DateField from "./field/date";
import Label from "./field/label";

export default {
  components: {
    Label,
    SelectField,
    TextField,
    DateField
  },
  props: {
    columns: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      filterColumns: [
        {
          value: "field",
          text: "対象フィールド"
        },
        {
          value: "cond",
          text: "条件"
        },
        {
          value: "value",
          text: "値"
        },
        {
          value: "remove",
          width: 50
        }
      ],
      input: {
        field: "",
        cond: "",
        value: ""
      }
    };
  },
  computed: {
    open: {
      get() {
        return this.$store.getters["filters/open"];
      },
      set(value) {
        if (!value) {
          this.cancel();
        }
      }
    },
    fieldOptions() {
      return this.columns.reduce((previous, current) => {
        if (current.filter) {
          previous.push({
            text: current.text,
            value:
              Object.prototype.toString.call(current.filter) ===
              "[object Object]"
                ? `${current.filter.table}.${current.filter.column}`
                : current.value
          });
        }

        return previous;
      }, []);
    },
    filters() {
      return this.$store.getters["filters/filters"];
    },
    conditions() {
      return this.$store.getters["filters/conditions"](this.fieldType);
    },
    allConditions() {
      return this.$store.getters["filters/allConditions"];
    },
    fieldType() {
      if (this.input.field) {
        const column = this.columns.find(
          column => column.value === this.input.field
        );

        return (column || {}).type || "string";
      }

      return "";
    },
    useValue() {
      if (this.input.cond) {
        const condition = this.conditions.find(
          condition => condition.cond === this.input.cond
        );

        return !!condition.useValue;
      }

      return false;
    },
    disabledAddButton() {
      return !(
        this.input.field &&
        this.input.cond &&
        (!this.useValue || this.input.value)
      );
    }
  },
  watch: {
    fieldType() {
      this.input.cond = "";
      this.input.value = "";
    },
    useValue() {
      this.input.value = "";
    }
  },
  methods: {
    clearForm() {
      this.input = {
        field: "",
        cond: "",
        value: ""
      };
    },
    add() {
      this.$store.commit("filters/add", this.input);
      this.clearForm();
    },
    remove(index) {
      this.$store.commit("filters/remove", { index });
    },
    apply() {
      this.$store.commit("filters/apply");
      this.clearForm();
    },
    clearAll() {
      this.$store.commit("filters/clearAll");
      this.clearForm();
    },
    cancel() {
      this.$store.commit("filters/cancel");
      this.clearForm();
    },
    renderField(value) {
      const fieldOption = this.fieldOptions.find(
        fieldOption => fieldOption.value === value
      );

      return (fieldOption || {}).text || "";
    },
    renderCondition(value) {
      const condition = this.allConditions.find(
        condition => condition.cond === value
      );

      return (condition || {}).text || "";
    }
  }
};
</script>

<style module lang="scss">
.dialog {
  max-width: 600px;

  .dialog-card-title {
    font-size: 18px;
    font-weight: 600;
  }

  .label {
    width: 200px;
  }
}
</style>
