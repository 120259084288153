<template>
  <v-container fluid :class="$style['container']">
    <v-overlay :value="processing">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <ToolBar>
      <template v-slot:left>
        <v-subheader class="text-no-wrap">{{ paginationText }}</v-subheader>
      </template>
      <template v-slot:right>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="!disabledAddButton"
              icon
              v-on="on"
              @click="moveToCreate"
            >
              <v-icon>mdi-plus-box</v-icon>
            </v-btn>
          </template>
          <span>新規登録</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="!disabledFilterButton"
              icon
              v-on="on"
              @click.stop="onFilter"
            >
              <v-badge
                :value="filtered"
                dot
                top
                color="red"
                offset-x="8"
                offset-y="8"
              >
                <v-icon>mdi-filter-outline</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>フィルタ</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="onRefresh" v-on="on">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>再読込</span>
        </v-tooltip>
      </template>
    </ToolBar>
    <div :class="$style['container-content']">
      <v-data-table
        :headers="columnsForTable"
        :items="entities"
        :items-per-page="perPage"
        :loading="loading"
        loading-text="読込中..."
        no-data-text="データがありません"
        disable-sort
        fixed-header
        hide-default-footer
      >
        <template v-slot:item.action="{ item }">
          <v-row class="flex-nowrap mx-n4" align-content="center">
            <slot name="actions.before" :item="item" />
            <slot name="actions.detail" :item="item">
              <DetailAction :action="() => moveToShow(item)" />
            </slot>
            <slot name="actions.remove" :item="item">
              <RemoveAction :action="() => remove(item)" />
            </slot>
            <slot name="actions.after" :item="item" />
          </v-row>
        </template>
      </v-data-table>
    </div>
    <v-pagination
      v-if="!disabledPagination"
      :class="$style['pagination']"
      :length="pageLength"
      :value="currentPage"
      :total-visible="7"
      @input="onChangePage"
    />
    <FilterModal :resourceName="resourceName" :columns="columns" />
  </v-container>
</template>

<script>
import ToolBar from "@/entries/front/components/app/toolbar";
import FilterModal from "@/entries/front/components/filter-modal";
import utility from "@/utility";
import RemoveAction from "@/entries/front/components/view/actions/remove";
import DetailAction from "@/entries/front/components/view/actions/detail";

export default {
  components: {
    ToolBar,
    RemoveAction,
    DetailAction,
    FilterModal
  },
  props: {
    resourceName: {
      type: String,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    pk: {
      type: String,
      default: "id"
    },
    disabledAddButton: {
      type: Boolean,
      default: false
    },
    disabledFilterButton: {
      type: Boolean,
      default: true
    },
    disabledPagination: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    loading() {
      return this.$store.getters[`${this.resourceName}/list/isLoading`];
    },
    processing() {
      return this.$store.getters[`${this.resourceName}/list/isProcessing`];
    },
    entities() {
      return this.$store.getters[`${this.resourceName}/list/entities`];
    },
    total() {
      return this.$store.getters[`${this.resourceName}/list/total`];
    },
    currentPage() {
      return this.$store.getters[`${this.resourceName}/list/page`];
    },
    perPage() {
      return this.$store.getters[`${this.resourceName}/list/perPage`];
    },
    pageLength() {
      return this.$store.getters[`${this.resourceName}/list/pageLength`];
    },
    filters() {
      return this.$store.getters["filters/filters"];
    },
    shouldFetch() {
      return this.$store.getters["filters/shouldFetch"];
    },
    filtered() {
      return this.$store.getters["filters/filtered"];
    },
    sort() {
      return this.$store.getters[`${this.resourceName}/list/sort`];
    },
    columnsForTable() {
      return this.columns.map((column) => {
        return {
          text: column.text,
          value: column.value,
          // align       : column.align,
          // sortable    : column.sortable,
          // filterable  : column.filterable,
          // divider     : true,
          // class       : column.class,
          width: column.width || undefined
        };
      });
    },
    paginationText() {
      if (!this.total) {
        return "";
      }

      const from = (this.currentPage - 1) * this.perPage + 1;
      const to = Math.min(this.currentPage * this.perPage, this.total);

      return `全 ${this.total} 件中 ${from} ~ ${to} 件目`;
    }
  },
  watch: {
    shouldFetch(should) {
      if (should) {
        this.fetch(this.currentPage)
          .then(() => {
            this.$store.commit("filters/fetched");
          })
          .catch(() => {
            this.$store.commit("filters/fetched");
          });
      }
    }
  },
  methods: {
    fetch(page) {
      return this.$store.dispatch(`${this.resourceName}/list/index`, {
        page,
        filters: this.filters,
        sort: this.sort
      });
    },
    onRefresh() {
      this.fetch(this.currentPage);
    },
    onFilter() {
      this.$store.commit("filters/open");
    },
    moveToCreate() {
      this.$router.push({
        path: `/${this.resourceName}/create`
      });
    },
    moveToShow(item) {
      this.$router.push({
        path: `/${this.resourceName}/${item[this.pk]}`
      });
    },
    remove(item) {
      utility
        .$confirm("削除しますか？", "確認", {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true
        })
        .then(() => {
          this.$store
            .dispatch(`${this.resourceName}/list/destroy`, {
              pk: item[this.pk]
            })
            .then(() => {
              this.fetch(this.currentPage);
            });
        })
        .catch(() => {});
    },
    onChangePage(page) {
      this.fetch(page);
    },
    onHeaderClick(columnClicked) {
      if (columnClicked.property === "buttons") {
        return;
      }

      const column = this.columns.find(
        (column) => column.name === columnClicked.property
      );

      if (!column.sortable) {
        return;
      }

      this.$store.commit(`${this.resourceName}/list/setSort`, {
        field: columnClicked.property,
        type: column.type || ""
      });

      this.fetch(this.currentPage);
    }
  },
  created() {
    this.$store.dispatch("filters/initialize");
    this.fetch(1);
  }
};
</script>

<style module lang="scss">
$base-index-header-height: 50px;
$base-index-footer-height: 60px;

.container {
  width: 100%;
  height: 100%;
  padding: 0;
  position: relative;

  .container-content {
    :global(.v-data-table.v-data-table--fixed-header) {
      height: calc(
        100vh - #{$base-index-header-height} - #{$base-index-footer-height}
      );

      :global(.v-data-table__wrapper) {
        height: 100%;
      }
    }
  }

  .pagination {
    height: $base-index-footer-height !important;
  }
}
</style>
